import { Icon, IconTypes } from '@/components/Icon/Icon'
import { StyledButton } from '@/components/IconButton/IconButton.styles'
import { styled } from 'styled-components'

const Container = styled(StyledButton)`
  outline: none;
  background-color: ${({ theme }) => theme.transactionDetailsPage.title.iconButton.bgColor};
  color: ${({ theme }) => theme.transactionDetailsPage.title.iconButton.color};
  border: ${({ theme }) => theme.transactionDetailsPage.title.iconButton.border};

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) =>
      theme.transactionDetailsPage.title.iconButton.border.slice(-7)};
    border: ${({ theme }) => theme.transactionDetailsPage.title.iconButton.border};
  }
`

interface IconButtonProps {
  onClick?: () => void
  iconType?: IconTypes
}

export const IconButton = ({ onClick, iconType }: IconButtonProps) => {
  const handleOnClick = () => {
    onClick && onClick()
  }

  return (
    <Container onClick={handleOnClick}>
      <Icon size="20px" type={iconType} />
    </Container>
  )
}

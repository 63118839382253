import { StoreContext } from '@/components/App'
import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { Icon } from '@/components/Icon/Icon'
import { IconButton } from './IconButton'
import { useTheme } from 'styled-components'
import { SidebarPopup } from '@/components/Generic'
import { SidebarTable, SidebarTableCell, SidebarTableHeaderCell } from './Components.styles'
import { EventLogsItem } from '@/store/TransactionDetails.store'
import { getFormatedDate } from '@/methods/handleDate'
import { toast } from 'react-toastify'

export const TransactionAuditLog: FC = observer(() => {
  const theme = useTheme()
  const store = useContext(StoreContext)
  const { singleTransactionAuditLog: transAuditLog } = store.TranslationsState.translations
  const { isAuditlogPopupVisible, auditLogs } = store.TransactionDetailsState

  const copyToClipboard = (object: any) => {
    navigator.clipboard.writeText(JSON.stringify(object, null, 2))

    toast.success('Copied to clipboard')
  }

  return (
    <SidebarPopup
      visibility={isAuditlogPopupVisible}
      {...theme.popup}
      header={
        <>
          <Icon size="40px" type="file-lookup" />
          <h3>{transAuditLog.pageTitle}</h3>
          <IconButton onClick={() => console.log('refresh')} iconType="refresh" />
        </>
      }
      handleVisibility={() => store.TransactionDetailsState.setAuditlogPopupVisibility(false)}
      width="670px"
    >
      <SidebarTable>
        <SidebarTableHeaderCell>{transAuditLog.type}</SidebarTableHeaderCell>
        <SidebarTableHeaderCell>{transAuditLog.publisher}</SidebarTableHeaderCell>
        <SidebarTableHeaderCell>{transAuditLog.payload}</SidebarTableHeaderCell>
        <SidebarTableHeaderCell>{transAuditLog.timestamp}</SidebarTableHeaderCell>

        {!!auditLogs.length &&
          auditLogs.map((item: EventLogsItem, index) => (
            <>
              <SidebarTableCell key={`type-${index}`}>{item.type}</SidebarTableCell>
              <SidebarTableCell key={`publisher-${index}`}>{item.publisher}</SidebarTableCell>
              <SidebarTableCell
                key={`payload-${index}`}
                onClick={() => copyToClipboard(item.payload)}
              >
                {'{...}'}
              </SidebarTableCell>
              <SidebarTableCell key={`timestamp-${index}`}>
                {getFormatedDate(Math.floor(new Date(item.timestamp).getTime() / 1000))}
              </SidebarTableCell>
            </>
          ))}
      </SidebarTable>
    </SidebarPopup>
  )
})

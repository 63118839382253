import { styled } from 'styled-components'

export const Page = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  margin-top: 56px;
  display: flex;
  padding: 40px;
  max-width: 664px;
  width: 100%;
  flex-direction: column;
  align-items: center;

  gap: 12px;
  border-radius: 8px;
  border: ${({ theme }) => theme.errorPage.border};
  background: ${({ theme }) => theme.errorPage.bgColor};
  box-shadow: ${({ theme }) => theme.errorPage.boxShadow};

  @media (max-width: 768px) {
    border: none;
    box-shadow: none;
  }
`

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.errorPage.iconBgColor};
  color: ${({ theme }) => theme.errorPage.iconColor};
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Header = styled.h1`
  color: ${({ theme }) => theme.errorPage.headerColor};
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  width: 100%;
  margin: 0;
`

export const Description = styled.p`
  color: ${({ theme }) => theme.errorPage.color};
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
  text-align: center;
  width: 100%;
  margin: 0;
`

export const Button = styled.button`
  margin-top: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: ${({ theme }) => theme.errorPage.buttonBgColor};
  color: ${({ theme }) => theme.errorPage.buttonColor};
  transition: filter 0.3s;

  &:hover {
    filter: brightness(${({ theme }) => theme.effects.hover.brightness});
  }
`

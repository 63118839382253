import { FC } from 'react'
import { Container, Wrap } from './LoadingSpinner.styles'

interface LoadingSpinnerProps {
  styleName?: 'primary' | 'secondary'
  width?: string
  height?: string
  centered?: boolean
  centerColor?: string
}

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  width,
  height,
  styleName,
  centered,
  centerColor,
}) => {
  return (
    <Wrap $centered={centered} $height={height}>
      <Container
        id="loading"
        width={width || '80px'}
        $styleName={styleName ? styleName : 'primary'}
        $centerColor={centerColor}
      ></Container>
    </Wrap>
  )
}

import { FC, useContext, useRef } from 'react'
import {
  Base64TextArea,
  WipePdfInputButton,
  FileInput,
  FileInputContainer,
  PdfLabel,
  SectionTitle,
  Tab,
  TabbedPdfContainer,
  TabContainer,
  UploadButton,
} from '../CreateTransactionPage.styles'
import { observer } from 'mobx-react'
import { StoreContext } from '@/components/App'
import { SelectInput } from '@/components/Generic'

const Input: FC = observer(() => {
  const store = useContext(StoreContext)
  const { createTransactionPage: trans } = store.TranslationsState.translations
  const { selectedPdfTab, mockPdf, filePdf, base64Pdf } = store.CreateTransactionState

  const fileInputRef = useRef<HTMLInputElement>()

  switch (selectedPdfTab) {
    case 'select':
      return (
        <>
          <PdfLabel>{trans.existingPDFmocks}</PdfLabel>
          <SelectInput options={[{ value: 'mockPdf', label: 'Mock PDF' }]} value={mockPdf} />
        </>
      )
    case 'upload':
      return (
        <FileInputContainer>
          {!filePdf?.name ? (
            <>
              <FileInput
                ref={fileInputRef}
                type="file"
                value={filePdf ? filePdf.name : ''}
                onChange={(e) => store.CreateTransactionState.handlePdfChange(e.target.files[0])}
              />
              <UploadButton
                onClick={() => {
                  fileInputRef.current.click()
                }}
              >
                {trans.uploadNewPDF}
              </UploadButton>
            </>
          ) : (
            <WipePdfInputButton>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                onClick={() => store.CreateTransactionState.clearPdfFile()}
              >
                <path
                  d="M12.15 3.3C7.53842 3.3 3.8 7.03842 3.8 11.65C3.8 16.2616 7.53842 20 12.15 20C16.7616 20 20.5 16.2616 20.5 11.65C20.5 7.03842 16.7616 3.3 12.15 3.3ZM2.5 11.65C2.5 6.32045 6.82045 2 12.15 2C17.4795 2 21.8 6.32045 21.8 11.65C21.8 16.9795 17.4795 21.3 12.15 21.3C6.82045 21.3 2.5 16.9795 2.5 11.65ZM16.1451 7.6548C16.399 7.90864 16.399 8.3202 16.1451 8.57404L13.0692 11.65L16.1451 14.7259C16.3989 14.9797 16.3989 15.3913 16.1451 15.6451C15.8913 15.8989 15.4797 15.8989 15.2259 15.6451L12.15 12.5692L9.07407 15.6451C8.82023 15.8989 8.40868 15.8989 8.15484 15.6451C7.90099 15.3913 7.90099 14.9797 8.15484 14.7259L11.2307 11.65L8.1548 8.57404C7.90096 8.3202 7.90096 7.90864 8.1548 7.6548C8.40864 7.40096 8.8202 7.40096 9.07404 7.6548L12.15 10.7307L15.2259 7.6548C15.4797 7.40096 15.8913 7.40096 16.1451 7.6548Z"
                  fill="currentColor"
                />
              </svg>
              <div>{filePdf?.name}</div>
            </WipePdfInputButton>
          )}
        </FileInputContainer>
      )
    case 'base64':
      return (
        <>
          <PdfLabel>{trans.pasteBase64}</PdfLabel>
          <Base64TextArea
            value={base64Pdf}
            onChange={(e) => store.CreateTransactionState.handlePdfChange(e.target.value)}
            placeholder={trans.pastePDFbase64}
            rows={1}
          />
        </>
      )
  }
})

export const TabbedPdfSelector: FC = observer(() => {
  const store = useContext(StoreContext)
  const { createTransactionPage: trans } = store.TranslationsState.translations
  const { selectedPdfTab } = store.CreateTransactionState

  return (
    <TabbedPdfContainer>
      <SectionTitle>{trans.PDFdocument}</SectionTitle>
      <TabContainer>
        <Tab
          $active={selectedPdfTab === 'select'}
          onClick={() => store.CreateTransactionState.setPdfTab('select')}
        >
          {trans.selectMock}
        </Tab>
        <Tab
          $active={selectedPdfTab === 'upload'}
          onClick={() => store.CreateTransactionState.setPdfTab('upload')}
        >
          {trans.uploadPDFFile}
        </Tab>
        <Tab
          $active={selectedPdfTab === 'base64'}
          onClick={() => store.CreateTransactionState.setPdfTab('base64')}
        >
          {trans.pasteBase64}
        </Tab>
      </TabContainer>

      <Input />
    </TabbedPdfContainer>
  )
})

import { action, makeAutoObservable, computed, runInAction } from 'mobx'
import { RootStore } from './Root.store'
import {
  GetAttributesResponse,
  PdfTab,
  TransactionPostRequest,
  TransactionResponseParsed,
} from '@/pages/CreateTransactionPage/types'
import { SelectOption } from '@/types/generic'
import { axiosInstance } from '@/axios/AxiosInstance'
import {
  DEFAULT_REDIRECT_URL,
  initObjV2,
  THEME_LIST,
  CONFIGURATION_LIST_V2,
  CONFIGURATION_LIST_V3,
  initObjV3,
} from '@/pages/CreateTransactionPage/constants'
import {
  convertToBase64,
  pdfSelector,
  postTransaction,
} from '@/pages/CreateTransactionPage/methods'

export class CreateTransactionStore {
  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      configIds: computed,
    })
    this.rootStore = rootStore

    this.jsonObject.configurationId = this.configuration
    this.jsonObject.preferredLanguage = this.language
    this.jsonObject.themeId = this.themeId
    this.jsonObject.redirectUrl = this.redirectUrl
    this.jsonObject.user.email = this.email
    this.jsonObject.user.phoneNumber = this.phoneNumber
  }

  rootStore: RootStore

  // statuses
  isLoading = false

  // input based body
  configuration = 'signicat.with.micropayment'
  language = 'en'
  themeId = 'signicatTheme'
  redirectUrl = DEFAULT_REDIRECT_URL
  phoneNumber = ''
  email = ''
  isV3Mode: boolean = false

  // editor based body
  jsonObject: TransactionPostRequest = initObjV2
  jsonEditorKey = 0

  // other state
  selectedPdfTab: PdfTab = 'select'
  mockPdf: SelectOption = { value: 'mockPdf', label: 'Mock PDF' }
  filePdf: File = undefined
  base64Pdf = ''

  // output
  results: TransactionResponseParsed[] = []

  // fetched select options
  fetchedConfigIds: string[] = []
  fetchedThemeIds: string[] = []

  // select options
  get configIds(): SelectOption[] {
    if (this.fetchedConfigIds?.length) {
      const returnValue = this.fetchedConfigIds.map((id) => ({ value: id, label: id }))
      this.configuration = returnValue[0].value

      return returnValue
    } else if (this.isV3Mode) {
      this.configuration = CONFIGURATION_LIST_V3[0].value
      return CONFIGURATION_LIST_V3
    } else {
      this.configuration = CONFIGURATION_LIST_V2[0].value
      return CONFIGURATION_LIST_V2
    }
  }

  get themeIds(): SelectOption[] {
    if (this.fetchedThemeIds?.length) {
      return this.fetchedThemeIds.map((id) => ({ value: id, label: id }))
    } else {
      return THEME_LIST
    }
  }

  get configurationOption(): SelectOption {
    return {
      value: this.configuration,
      label: this.configIds.find((option) => option.value === this.configuration)?.label,
    }
  }

  @action.bound setConfiguration = (configuration: string): void => {
    const oldConfiguration = this.configuration
    this.configuration = configuration

    if (
      (this.isV3Mode && configuration === 'demo.addressVerification') ||
      (this.isV3Mode && oldConfiguration === 'demo.addressVerification')
    ) {
      this.jsonObject = initObjV3(configuration)
    } else {
      this.jsonObject.configurationId = configuration
    }

    this.refreshJsonEditor()
  }

  @action.bound setLanguage = (language: string): void => {
    this.language = language
    this.jsonObject.preferredLanguage = language
    this.refreshJsonEditor()
  }

  @action.bound setThemeId = (themeId: string): void => {
    this.themeId = themeId
    this.jsonObject.themeId = themeId
    this.refreshJsonEditor()
  }

  @action.bound setRedirectUrl = (redirectUrl: string): void => {
    this.redirectUrl = redirectUrl
    this.jsonObject.redirectUrl = redirectUrl
    this.refreshJsonEditor()
  }

  @action.bound setPhoneNumber = (phoneNumber: string): void => {
    this.phoneNumber = phoneNumber
    this.jsonObject.user.phoneNumber = phoneNumber
    this.refreshJsonEditor()
  }

  @action.bound setEmail = (email: string): void => {
    this.email = email
    this.jsonObject.user.email = email
    this.refreshJsonEditor()
  }

  @action.bound setV3Mode = (isV3Mode: boolean): void => {
    console.log('isV3Mode', isV3Mode)
    const configuration = this.configuration

    runInAction(() => {
      this.isV3Mode = isV3Mode
      this.jsonObject = isV3Mode ? initObjV3(configuration) : initObjV2
      this.refreshJsonEditor()
    })
  }

  @action.bound setJsonObject = (jsonObject: TransactionPostRequest): void => {
    this.jsonObject = jsonObject

    this.configuration = jsonObject.configurationId
    this.language = jsonObject.preferredLanguage
    this.themeId = jsonObject.themeId
    this.redirectUrl = jsonObject.redirectUrl
    this.phoneNumber = jsonObject.user.phoneNumber
    this.email = jsonObject.user.email
  }

  @action.bound refreshJsonEditor = (): void => {
    this.jsonEditorKey = this.jsonEditorKey + 1
  }

  @action.bound setPdfTab = (tab: PdfTab): void => {
    this.selectedPdfTab = tab
  }

  @action.bound handlePdfChange = (file: File | SelectOption | string): void => {
    if (file instanceof File) {
      this.filePdf = file
    } else if (typeof file === 'string') {
      this.base64Pdf = file
    } else {
      this.mockPdf = file
    }
  }

  @action.bound clearPdfFile = (): void => {
    this.filePdf = undefined
  }

  @action.bound async fetchConfigIds() {
    if (
      this.rootStore.Auth.currentUserId?.length &&
      this.rootStore.AppState.selectedTenant?.length &&
      this.rootStore.AppState.selectedTenant !== 'null' &&
      this.rootStore.AppState.selectedTenant !== 'undefined'
    ) {
      try {
        const res = await axiosInstance.get<GetAttributesResponse>(`/tenants/attributes`, {
          withCredentials: true,
          params: {
            tenantId: this.rootStore.AppState.selectedTenant,
          },
        })

        this.fetchedConfigIds = res.data.configurationIds
        this.fetchedThemeIds = res.data.themeIds
      } catch (e) {
        this.fetchedConfigIds = []
        this.fetchedThemeIds = []
      }
    }
  }

  @action.bound async postCreateTransaction() {
    let selectedPDFForRequest = ''

    switch (this.selectedPdfTab) {
      case 'select':
        selectedPDFForRequest = pdfSelector(this.mockPdf.value)
        break
      case 'upload':
        selectedPDFForRequest = (await convertToBase64(this.filePdf)).split(',')[1]
        break
      case 'base64':
        selectedPDFForRequest = this.base64Pdf
        break
    }

    this.isLoading = true

    try {
      const submitRes = await postTransaction(
        this.rootStore.AppState.selectedTenant,
        this.jsonObject,
        selectedPDFForRequest,
        this.isV3Mode
      )

      this.results = [submitRes, ...this.results]
    } catch (error) {
      this.results = [
        {
          transactionId: '-',
          proceedToken: '-',
          proceedUrl: '-',
          status: 'Error',
          date: '-',
          apiUrl: '-',
        },
        ,
        ...this.results,
      ]
    }

    this.isLoading = false
  }
}

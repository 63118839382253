import { format } from 'date-fns'
import { axiosInstance } from '@/axios/AxiosInstance'
import { pdfExample } from '@constants/PDFsBase64.constant'
import { toast } from 'react-toastify'
import { TransactionPostRequest, TransactionPostResponse, TransactionResponseParsed } from './types'
import { SelectOption } from '@/types/generic'

export const toSelect = (id: string, options: SelectOption[]): SelectOption => {
  return { value: id, label: options.find((option) => option.value === id)?.label }
}

export const isValidUrl = (url: string) => {
  try {
    const newUrl = new URL(url)
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:'
  } catch (err) {
    return false
  }
}

export const pdfSelector = (pdfName: string) => {
  switch (pdfName) {
    case 'pdfExample':
      return pdfExample
    default:
      return ''
  }
}

export const convertToBase64 = (file: File): Promise<string> => {
  const reader = new FileReader()
  reader.readAsDataURL(file)

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

export const postTransaction = async (
  tenantId: string,
  transaction: TransactionPostRequest,
  pdfDocument?: string,
  isV3?: boolean
): Promise<TransactionResponseParsed> => {
  try {
    const response = await axiosInstance.post<TransactionPostResponse>(
      `/transactions`,
      {
        ...transaction,
        ...(pdfDocument.length > 0 && { pdfDocument }),
      },
      {
        withCredentials: true,
        params: {
          tenantId,
          isV3,
        },
      }
    )

    let status = response.status.toString()
    status = status.slice(0, 1) === '2' ? `${response.status} OK` : `${response.status} FAIL`

    const now = new Date()

    return {
      ...response.data,
      status,
      date: format(now, 'HH:mm, dd.MM.yyyy'),
      apiUrl: `${axiosInstance.defaults.baseURL}/transactions/${response.data.transactionId}?tenantId=${tenantId}${
        isV3 ? '&isV3=true' : ''
      }`,
    }
  } catch (error) {
    interface CustomError {
      response: {
        status: number
        statusText: string
        data: {
          message: string
        }
      }
    }

    const errorData = error as CustomError

    toast('Unable to create transaction: ' + errorData?.response?.data?.message, { type: 'error' })

    const now = new Date()
    return {
      transactionId: undefined,
      proceedToken: undefined,
      proceedUrl: undefined,
      status: errorData?.response?.status?.toString() + ' FAIL',
      date: format(now, 'HH:mm, dd.MM.yyyy'),
      apiUrl: undefined,
      errorMessage: errorData?.response?.data?.message,
    }
  }
}

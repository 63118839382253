import { FC, useRef, useState } from 'react'
import {
  SimpleLine,
  ObjectSubtree as StyledObjectSubtree,
  SubTreeObject,
  SubTreeRow,
} from '../Components.styles'
import { RecursiveObjectRenderer } from './RecursiveObjectRenderer'
import { ResolveValueType } from './ResolveValueType'
import { CollapseButton } from './CollapseButton'

interface ObjectSubtreeProps {
  propertyKey: string
  data: any
  isFirstIteration?: boolean
}

export const ObjectSubtree: FC<ObjectSubtreeProps> = ({ propertyKey, data, isFirstIteration }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const spanRef = useRef<HTMLSpanElement>(null)

  return (
    <>
      <CollapseButton onClick={() => setIsCollapsed(!isCollapsed)} isCollapsed={isCollapsed} />
      <StyledObjectSubtree
        $isCollapsed={isCollapsed}
        $baseHeight={spanRef?.current?.clientHeight}
        $isFirstIteration={isFirstIteration}
      >
        <span ref={spanRef}>{propertyKey}</span>

        {Object.entries(data).map(([key, value]) => {
          if (typeof value === 'object' && value !== null) {
            return (
              <SubTreeObject key={key}>
                {/* <SimpleLine ref={simpleLineRef} $parentOffset={offset} /> */}
                <span>{key}</span>
                <RecursiveObjectRenderer data={value as {}} />
              </SubTreeObject>
            )
          } else {
            return (
              <SubTreeRow key={key}>
                {/* <SimpleLine ref={simpleLineRef} $parentOffset={offset} /> */}
                <span>
                  {key}
                  {':'}
                </span>
                <ResolveValueType value={value} />
              </SubTreeRow>
            )
          }
        })}
      </StyledObjectSubtree>
    </>
  )
}

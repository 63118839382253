import { observer } from 'mobx-react'
import { FC, useContext, useState } from 'react'
import { IDWrap, TableCell } from '../TransactionsTable.styles'
import { StoreContext } from '@/components/App'
import { formatUnixDate } from '@/pages/TransactionDetailsPage/methods'
import { Pill } from '../../../Components/Pill'
import { TransactionListItem } from '@/types/transactions'
import { navigate } from 'wouter/use-browser-location'

interface HandleValueTypeProps {
  value: string | number | boolean | string[] | undefined
  column: string
}

const handleValueType = ({ value, column }: HandleValueTypeProps) => {
  if (!value) return ''

  switch (column) {
    case 'transactionId':
      return <IDWrap>{value}</IDWrap>
    case 'creationUnixTimeSeconds':
      return formatUnixDate(value)
    case 'completionUnixTimeSeconds':
      return formatUnixDate(value)
    case 'externalUserRefId':
      return <a>{value}</a>
    case 'status':
      return <Pill status={value as string} />
    default:
      return value
  }
}

export const TableRow: FC<TransactionListItem> = observer((props) => {
  const store = useContext(StoreContext)
  const { visibleColumns } = store.TransactionListState

  const [isHovering, setIsHovering] = useState(false)
  const [mouseDownStamp, setMouseDownStamp] = useState('')

  const openTransactionDetails = () => {
    const mouseUpStamp = Date.now().toString()
    //  TODO: Improve this logic
    // if (mouseDownStamp && parseInt(mouseUpStamp) - parseInt(mouseDownStamp) > 100) return

    navigate(`/transactions/${props.transactionId}`)
  }

  return (
    <>
      {visibleColumns.map((column) => {
        return (
          <TableCell
            key={`${props.transactionId}-${column}`}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            $isHovering={isHovering}
            onMouseUp={openTransactionDetails}
            onMouseDown={(e) => {
              e.preventDefault()
              setMouseDownStamp(Date.now().toString())
            }}
          >
            {handleValueType({ value: props[column], column })}
          </TableCell>
        )
      })}
    </>
  )
})

import { FC } from 'react'
import { SubTreeRow } from '../Components.styles'
import { ResolveValueType } from './ResolveValueType'
import { ObjectSubtree } from './ObjectSubtree'

interface RecursiveObjectRendererProps {
  data: { [key: string]: {} | string | number | boolean }
  propertyKey?: string
  isFirstLoop?: boolean
  isFirstLoopBasicInfo?: boolean
}

export const RecursiveObjectRenderer: FC<RecursiveObjectRendererProps> = ({
  data,
  propertyKey,
  isFirstLoop,
  isFirstLoopBasicInfo,
}) => {
  // propertyKey && console.log('propertyKey', propertyKey)

  try {
    const type = typeof data

    if (type === 'object' && data !== null) {
      return (
        <ObjectSubtree
          propertyKey={propertyKey}
          data={data}
          isFirstIteration={isFirstLoop || isFirstLoopBasicInfo}
        />
      )
    } else {
      return (
        <SubTreeRow $isFirstLoop={isFirstLoop}>
          <span>
            {propertyKey}
            {':'}
          </span>
          <ResolveValueType value={data} />
        </SubTreeRow>
      )
    }
  } catch (error) {
    return <></>
  }
}

import { css, styled } from 'styled-components'

export const Page = styled.div`
  width: 100%;
  padding: 40px 48px;
  height: calc(100vh - 64px);
  container-type: inline-size;
  /* overflow-y: hidden; */
`

export const Title = styled.h1`
  display: flex;
  align-items: center;
  gap: 12px;

  color: ${({ theme }) => theme.createTransactionPage.title.color};
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 32px;

  span {
    color: ${({ theme }) => theme.createTransactionPage.title.iconColor};
  }
`

export const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.createTransactionPage.sectionTitleColor};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`

export const Content = styled.div`
  display: grid;
  width: 100%;
  min-height: calc(100vh - 220px);
  grid-template-columns: calc(50% - 24px) calc(50% - 24px);
  gap: 32px 48px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`

export const ContentDesktopColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 768px) {
    display: contents;
  }
`

export const RequestBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > div {
    margin-bottom: 0px !important;
  }
`

export const JSONEditorContainer = styled.div`
  height: 200px;
  width: 100%;

  h2 {
    margin-bottom: 16px;
  }
`

export const TabbedPdfContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.createTransactionPage.tab.underlineColor};
  color: ${({ theme }) => theme.createTransactionPage.tab.color};
`

export const Tab = styled.div<{ $active: boolean }>`
  color: inherit;
  border-bottom: 1px solid ${({ theme }) => theme.createTransactionPage.tab.underlineColor};
  padding: 8px 0;
  margin-bottom: -1px;
  cursor: pointer;

  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.createTransactionPage.tab.activeColor};
      border-bottom: 1px solid
        ${({ theme }) => theme.createTransactionPage.tab.activeUnderlineColor};
    `}
`

export const ActionButtonContainer = styled.div`
  display: flex;
  gap: 32px;
  justify-content: flex-end;
  align-items: center;
`

export const BaseButton = styled.button`
  width: 180px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;

    &:hover {
      filter: brightness(1);
    }
  }
`

export const ClearButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.createTransactionPage.secondaryButton.bgColor};
  color: ${({ theme }) => theme.createTransactionPage.secondaryButton.color};
  border: ${({ theme }) => theme.createTransactionPage.secondaryButton.border};

  svg {
    color: ${({ theme }) => theme.createTransactionPage.secondaryButton.iconColor};
  }
`

export const CreateButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.createTransactionPage.primaryButton.bgColor};
  color: ${({ theme }) => theme.createTransactionPage.primaryButton.color};
  border: ${({ theme }) => theme.createTransactionPage.primaryButton.border};

  svg {
    color: ${({ theme }) => theme.createTransactionPage.primaryButton.iconColor};
  }

  &:hover {
    filter: brightness(${({ theme }) => theme.effects.hover.brightness});
  }
`

export const PdfLabel = styled.label`
  color: ${({ theme }) => theme.createTransactionPage.sectionTitleColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: -2px 0 -8px 0;
`

export const FileInputContainer = styled.div``
export const FileInput = styled.input`
  width: 1px;
  height: 1px;
  opacity: 0;
`
export const UploadButton = styled(CreateButton)`
  height: 40px;
  width: 160px;

  &:hover {
    filter: brightness(${({ theme }) => theme.effects.hover.brightness});
  }
`

export const Base64TextArea = styled.textarea`
  width: 100%;
  height: 40px;

  max-width: 100%;
  min-width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: ${({ theme }) => theme.textInput.border};
  resize: none;
  padding: 9px;
  border-radius: 5px;
  border: ${({ theme }) => theme.textInput.border};
  background-color: inherit;
  color: ${({ theme }) => theme.textInput.color};
  transition: 0.5s ease-out;
  cursor: text;
`

export const ResultList = styled.div`
  margin-top: -16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  max-height: calc(100vh - 220px);
  overflow-y: auto;
`

export const ResultItem = styled.div<{ $isCollapsed?: boolean }>`
  container-type: inline-size;
  width: 100%;
  padding: 24px 32px;
  border: ${({ theme }) => theme.createTransactionPage.result.border};
  box-shadow: ${({ theme }) => theme.createTransactionPage.result.boxShadow};
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ResultHeader = styled.div`
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ResultTimestamp = styled.div`
  color: ${({ theme }) => theme.createTransactionPage.result.dateColor};
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;

  display: flex;
  gap: 10px;
  align-items: center;
`

export const CollapseButton = styled.button<{ $isCollapsed?: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.createTransactionPage.result.iconColor};

  svg {
    transform: rotate(${({ $isCollapsed }) => ($isCollapsed ? '0deg' : '180deg')});
  }
`

export const ApiUrlContainer = styled.a`
  display: block;
  width: 100%;
  height: 40px;
  color: ${({ theme }) => theme.createTransactionPage.result.linkColor};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
`

export const WipePdfInputButton = styled(ClearButton)`
  width: 240px;
  height: 40px;
  cursor: default;

  & > svg {
    cursor: pointer;
  }

  & > div {
    width: calc(100% - 64px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const ResultValuesContainer = styled.div<{
  $isCollapsed?: boolean
}>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: ${({ $isCollapsed }) => ($isCollapsed ? '0px' : '1000px')};
  margin: ${({ $isCollapsed }) => ($isCollapsed ? '-16px 0' : '0')};
  transition:
    max-height 0.5s,
    margin 0.5s;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;

  & > label {
    display: block;
    height: 20px;
    color: ${({ theme }) => theme.createTransactionPage.result.labelColor};
    font-weight: 500;
    margin-bottom: 8px;
  }

  & > div {
    height: 40px;
    color: ${({ theme }) => theme.createTransactionPage.result.valueColor};
    background-color: ${({ theme }) => theme.createTransactionPage.result.valueBgColor};
    font-weight: 400;
    margin-bottom: 16px;
    padding: 10px 16px;
    /* display: flex;
    align-items: center; */

    & > div {
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:last-child {
      margin-bottom: 0;
      height: 60px;
    }
  }
`

export const ResultFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin-top: 16px;

  button {
    border-radius: 100px;
    height: 40px;
    padding: 0 24px;
    background: none;
    border: ${({ theme }) => theme.createTransactionPage.resultButton.border};
    cursor: pointer;
    color: ${({ theme }) => theme.createTransactionPage.resultButton.color};
    background-color: ${({ theme }) => theme.createTransactionPage.resultButton.bgColor};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    svg {
      color: ${({ theme }) => theme.createTransactionPage.resultButton.iconColor};
    }

    @container (width < 450px) {
      width: 40px;
      border-radius: 50%;
      padding: 0px;
      span {
        display: none;
      }
    }
  }
`

import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { RootStore } from './Root.store'
import { ContainerStates, Provider, Transaction } from '@/pages/TransactionDetailsPage/types'
import { dataMock } from '@/pages/TransactionDetailsPage/mock'
import { axiosInstance } from '@/axios/AxiosInstance'
import { DEV_MODE } from '@/constants/envs'
import { stringifyArraysInObject } from '@/pages/TransactionDetailsPage/methods'
import { parseQueryParams } from '@/methods/paramsComposer'
import qs from 'qs'

interface InitTransactionPreviewParams {
  transactionId: string
  isV3: boolean
  selectedTenant: string
}

export type EventLogsItem = {
  type: string
  publisher: string
  payload: string
  timestamp: string
}

export type TransactionHistoryItem = {
  category?: string
  step: string
  sort?: string
  timestamp: string
}

export class TransactionDetailsStore {
  rootStore: RootStore
  transactionId: string = ''
  transaction: Transaction | undefined = undefined
  auditLogs: EventLogsItem[] = []
  history: TransactionHistoryItem[] = []

  isTransactionLoading = true
  isAuditLogsLoading = true
  isHistoryLoading = true

  isV3 = false
  selectedTenant = ''

  isDeleting = false
  isDeletePopupVisible = false

  isHistoryPopupVisible = false
  isAuditlogPopupVisible = false

  get providers(): Provider[] {
    if (!this.transaction) return []

    return Object.keys(this.transaction.providers).map((key) => {
      return {
        providerName: key,
        ...this.transaction.providers[key],
      }
    })
  }

  // #TODO: use this in the new render logic maybe
  get providersStringified(): string[] {
    if (!this.transaction) return []

    return Object.keys(this.transaction.providers).map((key) => {
      const stringified = stringifyArraysInObject({
        providerName: key,
        ...this.transaction.providers[key],
      })

      return JSON.stringify(stringified, null, 1)
    })
  }

  containerStates: ContainerStates = {}

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      providers: computed,
    })
    this.rootStore = rootStore
  }

  @action.bound initTransactionPreview = ({
    transactionId,
    isV3,
    selectedTenant,
  }: InitTransactionPreviewParams): void => {
    runInAction(() => {
      this.transactionId = transactionId
      this.isV3 = isV3
      this.selectedTenant = localStorage.getItem('selectedTenant') || selectedTenant

      this.fetchTransaction()
      // this.fetchAuditLogs()
      // this.fetchHistory()
    })
  }

  @action.bound fetchTransaction = async (): Promise<void> => {
    this.isTransactionLoading = true
    try {
      if (DEV_MODE) {
        this.transaction = dataMock as Transaction
        this.isTransactionLoading = false
        return
      }

      const { data } = await axiosInstance.get(`/transactions/${this.transactionId}`, {
        params: {
          tenantId: this.selectedTenant,
          isV3: this.isV3,
        },
      })

      runInAction(() => {
        this.transaction = data as Transaction
        this.isTransactionLoading = false
      })
    } catch (error) {
      console.error('Error fetching transaction', error)
      this.isTransactionLoading = false
    }
  }

  @action.bound fetchAuditLogs = async (pageToken?: string): Promise<void> => {
    const response = await axiosInstance.get<{
      items: EventLogsItem[]
      nextPageToken?: string
    }>('/audit-log/events/', {
      withCredentials: true,
      params: parseQueryParams({
        tenantId: this.selectedTenant,
        transactionId: this.transactionId,
        timestamp: Number(this.transaction?.creationUnixTimeSeconds),
        nextPageToken: pageToken && pageToken.length ? pageToken : undefined,
      }),
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })

    if (response.data.nextPageToken?.length) {
      // setPageTokenByIndex(currentPageIndex + 1, response.data.nextPageToken)
    }

    // setEventLogs([...eventLogs, ...response.data.items])
    this.auditLogs = [...this.auditLogs, ...response.data.items]
  }

  @action.bound fetchHistory = async (): Promise<void> => {
    const response = await axiosInstance.get<{
      items: TransactionHistoryItem[]
    }>(`/transactions/${this.transactionId}/history`, {
      withCredentials: true,
      params: {
        tenantId: this.selectedTenant,
        isV3: this.isV3,
      },
    })

    this.history = [
      // ...transactionHistory,
      ...response.data.items.filter((step) => step.timestamp.length !== 0),
    ]
  }

  @action.bound setTransactionDetails = (transaction: Transaction): void => {
    this.transaction = transaction
  }

  @action.bound setContainerStates = (containerStates: ContainerStates): void => {
    this.containerStates = containerStates
  }

  @action.bound setContainerCollapsedState = (containerId: string, isCollapsed: boolean): void => {
    this.setAllContainersCollapsed()
    console.log('setContainerCollapsedState', containerId, isCollapsed)

    if (!this.containerStates[containerId]) {
      this.containerStates[containerId] = {
        providerName: containerId,
        isCollapsed: isCollapsed,
        isHighlighted: false,
      }
    } else {
      this.containerStates[containerId].isCollapsed = isCollapsed
    }
  }

  @action.bound setAllContainersCollapsed = (): void => {
    Object.keys(this.containerStates).forEach((key) => {
      this.containerStates[key].isCollapsed = true
    })
  }

  @action.bound setContainerHighlighted = async (containerId: string) => {
    this.containerStates[containerId].isHighlighted = true

    setTimeout(() => {
      this.containerStates[containerId].isHighlighted = false
    }, 2000)
  }

  @action.bound setDeleting = (isDeleting: boolean): void => {
    this.isDeleting = isDeleting
  }

  @action.bound setDeletePopupVisibility = (isVisible: boolean): void => {
    this.isDeletePopupVisible = isVisible
  }

  @action.bound setHistoryPopupVisibility = (isVisible: boolean): void => {
    this.isHistoryPopupVisible = isVisible
  }

  @action.bound setAuditlogPopupVisibility = (isVisible: boolean): void => {
    this.isAuditlogPopupVisible = isVisible
  }
}

import { StoreContext } from '@/components/App'
import { Page, Content, IconWrapper, Header, Description, Button } from '@/styles/ErrorPages'
import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { navigate } from 'wouter/use-browser-location'

export const UnauthorizedPage: FC = observer(() => {
  const store = useContext(StoreContext)
  const { unauthorizedPage: trans } = store.TranslationsState.translations

  return (
    <Page>
      <Content>
        <IconWrapper>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M69.2229 58.9718L43.4011 16.1249C41.9079 13.6472 38.0921 13.6472 36.5989 16.1249L10.7771 58.9718C9.40165 61.254 11.0188 64.4 14.1781 64.4H65.8219C68.9812 64.4 70.5984 61.254 69.2229 58.9718ZM45.3424 14.955C42.968 11.015 37.032 11.015 34.6575 14.955L8.83568 57.8018C6.46126 61.7417 9.42929 66.6667 14.1781 66.6667H65.8219C70.5707 66.6667 73.5387 61.7417 71.1643 57.8018L45.3424 14.955Z"
              fill="currentColor"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M40.0001 27.8C38.5578 27.8 37.4419 29.0641 37.6208 30.4952L39.626 46.5364C39.6496 46.7251 39.81 46.8667 40.0001 46.8667C40.1903 46.8667 40.3507 46.7251 40.3742 46.5364L42.3794 30.4952C42.5583 29.0641 41.4424 27.8 40.0001 27.8ZM35.3717 30.7763C35.0237 27.9923 37.1944 25.5333 40.0001 25.5333C42.8058 25.5333 44.9766 27.9923 44.6286 30.7763L42.6234 46.8175C42.458 48.1405 41.3334 49.1333 40.0001 49.1333C38.6668 49.1333 37.5422 48.1405 37.3768 46.8175L35.3717 30.7763Z"
              fill="currentColor"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M39.9999 53.1333C39.153 53.1333 38.4665 53.8198 38.4665 54.6667C38.4665 55.5135 39.153 56.2 39.9999 56.2C40.8467 56.2 41.5332 55.5135 41.5332 54.6667C41.5332 53.8198 40.8467 53.1333 39.9999 53.1333ZM36.1999 54.6667C36.1999 52.568 37.9012 50.8667 39.9999 50.8667C42.0986 50.8667 43.7999 52.568 43.7999 54.6667C43.7999 56.7653 42.0986 58.4667 39.9999 58.4667C37.9012 58.4667 36.1999 56.7653 36.1999 54.6667Z"
              fill="currentColor"
            />
          </svg>
        </IconWrapper>
        <Header>{trans.title}</Header>
        <Description>{trans.description}</Description>

        <Button onClick={() => navigate('/transactions')}>{trans.button}</Button>
      </Content>
    </Page>
  )
})

import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  100% {
    transform: rotate(1turn);
  }

`

interface ContainerProps {
  width?: string
  $styleName?: 'primary' | 'secondary'
  $centerColor?: string
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  z-index: 0;
  width: ${(props) => (props.width ? props.width : '50px')};
  height: ${(props) => (props.width ? props.width : '50px')};
  display: block;
  box-sizing: border-box;
  border-radius: 50px;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: ${(props) => props.theme.loadingSpinner[props.$styleName].color};
    background-repeat: no-repeat;
    background-size:
      50% 50%,
      50% 50%;
    background-position:
      0 0,
      100% 0,
      100% 100%,
      0 100%;
    background-image: linear-gradient(
        ${(props) => props.theme.loadingSpinner[props.$styleName].color},
        ${(props) => props.theme.loadingSpinner[props.$styleName].color}
      ),
      linear-gradient(
        ${(props) => props.theme.loadingSpinner[props.$styleName].bgColor},
        ${(props) => props.theme.loadingSpinner[props.$styleName].bgColor}
      ),
      linear-gradient(
        ${(props) => props.theme.loadingSpinner[props.$styleName].bgColor},
        ${(props) => props.theme.loadingSpinner[props.$styleName].bgColor}
      );
    animation: ${rotate} 0.6s cubic-bezier(0.67, 0.34, 0.3, 0.71) infinite;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: ${({ theme, $centerColor }) => $centerColor || theme.colors.backgroundColor};
    border-radius: 50px;
  }
`

export const Wrap = styled.div<{ $centered?: boolean; $height?: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: ${({ $height }) => $height || 'auto'};

  ${({ $centered, $height }) =>
    $centered &&
    `
    height: ${$height || '100%'}};
  `}
`

import { observer } from 'mobx-react'
import { FC, useContext, useEffect, useState } from 'react'
import { StoreContext } from '@/components/App'
import { Redirect } from 'wouter'
import {
  Content,
  StyledLogoSection,
  StyledFormSection,
  StyledSignicatLogo,
  StyledTitleSection,
  StyledForm,
  WarningMessage,
  StyledButtonSection,
} from './LoginPage.styles'
import { SignicatLogo } from './Components/SignicatLogo'
import { useTheme } from 'styled-components'
import { useDarkMode } from '@/hooks/useDarkmode'
import { TextInput } from '@/components/Generic/TextInput/TextInput'
import { Button } from '@/components/Generic/Button/Button'
import { LoadingSpinner } from '@/components/Generic/LoadingSpinner/LoadingSpinner'
import { Icon } from '@/components/Icon/Icon'
import { API_URL } from '@/constants/envs'

export const LoginPage: FC = observer(() => {
  const theme = useTheme()
  const { isDarkMode } = useDarkMode()

  const store = useContext(StoreContext)
  const { loginPage: trans } = store.TranslationsState.translations

  const { isLogged, shouldLogout, currentUserId } = store.Auth

  const [redirecting, setRedirecting] = useState(false)
  const [unauthMessage, setUnauthMessage] = useState('')
  const [email, setEmail] = useState('')
  const [domain, setDomain] = useState('')
  const [authDisabled, setAuthDisabled] = useState(true)

  const handleAuth = () => {
    setRedirecting(true)
    store.Auth.setShouldLogout(false)
    window.location.href = `${API_URL}/redirect-to-auth?domain=${domain}`
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  useEffect(() => {
    const emailSplit = email.split('@')
    if (emailSplit.length > 1) {
      setDomain(emailSplit[1])
    }
    validateEmail(email) && setAuthDisabled(false)
  }, [email])

  useEffect(() => {
    setUnauthMessage(location.pathname.split('/')[2])
  }, [])

  return (
    <Content>
      <StyledLogoSection>
        <StyledSignicatLogo>
          <SignicatLogo />
        </StyledSignicatLogo>
        <StyledTitleSection $gap="12px" width="448px">
          <h4>{trans.welcome}</h4>
          <h2>Signicat Onboarding Dashboard</h2>
        </StyledTitleSection>
      </StyledLogoSection>
      <StyledFormSection>
        {!redirecting ? (
          <>
            {unauthMessage && unauthMessage.length && (
              <WarningMessage>
                <Icon type="warning" size="20px" />
                {unauthMessage}
              </WarningMessage>
            )}
            <StyledTitleSection>
              <h3>{trans.welcomeBack}</h3>
              <p>{trans.pleaseLogIn}</p>
            </StyledTitleSection>

            <StyledForm onSubmit={handleAuth}>
              <TextInput
                type="email"
                name="email"
                required
                value={email}
                onChange={(value: string) => setEmail(value)}
                placeholder={trans.enterEmail}
                label={trans.emailAddress}
              />
            </StyledForm>
            <StyledButtonSection>
              <Button onClick={handleAuth} disabled={authDisabled} width="100%">
                {trans.logIn}
              </Button>
            </StyledButtonSection>
          </>
        ) : (
          <>
            <StyledTitleSection>
              <h3>{trans.redirecting}</h3>
              <p>{trans.pleaseWaitRedirectionToMicrosoft}</p>
            </StyledTitleSection>
            <LoadingSpinner width="60px" />
          </>
        )}
      </StyledFormSection>

      {isLogged && currentUserId.length && !shouldLogout ? (
        <Redirect to={'/transactions'} />
      ) : (
        <></>
      )}
    </Content>
  )
})

import { StoreContext } from '@/components/App'
import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { Icon } from '@/components/Icon/Icon'
import { IconButton } from './IconButton'
import { useTheme } from 'styled-components'
import { SidebarPopup } from '@/components/Generic'
import { SidebarTable, SidebarTableCell, SidebarTableHeaderCell } from './Components.styles'
import { TransactionHistoryItem } from '@/store/TransactionDetails.store'
import { getFormatedDate } from '@/methods/handleDate'

export const TransactionHistory: FC = observer(() => {
  const theme = useTheme()
  const store = useContext(StoreContext)
  const { singleTransactionHistory: transHistory } = store.TranslationsState.translations
  const { isHistoryPopupVisible, history } = store.TransactionDetailsState

  return (
    <SidebarPopup
      visibility={isHistoryPopupVisible}
      {...theme.popup}
      header={
        <>
          <Icon size="40px" type="history" />
          <h3>{transHistory.pageTitle}</h3>
          <IconButton onClick={() => console.log('refresh')} iconType="refresh" />
        </>
      }
      handleVisibility={() => store.TransactionDetailsState.setHistoryPopupVisibility(false)}
      width="670px"
    >
      <SidebarTable>
        <SidebarTableHeaderCell>{transHistory.step}</SidebarTableHeaderCell>
        <SidebarTableHeaderCell>{transHistory.timestamp}</SidebarTableHeaderCell>

        {!!history.length &&
          history.map((item: TransactionHistoryItem, index) => (
            <>
              <SidebarTableCell key={`step-${index}`}>{item.step}</SidebarTableCell>
              <SidebarTableCell key={`timestamp-${index}`}>
                {getFormatedDate(Math.floor(new Date(item.timestamp).getTime() / 1000))}
              </SidebarTableCell>
            </>
          ))}
      </SidebarTable>
    </SidebarPopup>
  )
})

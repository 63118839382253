import Select from 'react-select'
import { SelectOption } from '@/types/generic'
import { SelectMenuPortalGlobalStyle, StyledSelectWrapper } from './SelectInput.styles'
import { FC } from 'react'

interface SelectInputProps {
  onChange?: (option: SelectOption | null) => void
  options?: SelectOption[]
  value?: SelectOption
  classNamePrefix?: string
  height?: number
  disabled?: boolean
}

export const SelectInput: FC<SelectInputProps> = ({
  onChange,
  options,
  value,
  classNamePrefix,
  height,
  disabled,
}) => {
  const onChangeHandler = (e: SelectOption) => {
    onChange && onChange(e)
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      height: height || 40,
      minHeight: height || 40,
    }),
  }

  return (
    <StyledSelectWrapper>
      <Select
        options={options}
        value={value}
        onChange={onChangeHandler}
        classNamePrefix={classNamePrefix || 'select-input'}
        styles={customStyles}
        menuPosition="absolute"
        menuPlacement="auto"
        menuPortalTarget={document.body}
        isDisabled={disabled}
      />
      <SelectMenuPortalGlobalStyle />
    </StyledSelectWrapper>
  )
}

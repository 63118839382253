import { css, styled } from 'styled-components'
import { StyledCollapseButton } from '../RecursiveObjectRenderer/CollapseButton'

export const BasicInfoContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: calc(50% - 12px) 1px calc(50% - 12px);
  grid-auto-rows: 40px 24px 1fr;
  gap: 0 24px;
  max-height: 600px;

  background-color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.backgroundColor};
  outline: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.border};
  box-shadow: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.boxShadow};
  padding: 30px 20px;
  border-radius: 8px;

  overflow: hidden;
`

export const BasicInfoBottomContent = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
`

export const BasicInfoLine = styled.div`
  grid-column: 1;
  grid-row: 2;
  width: 1px;
  height: 24px;
  background-color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.treeLineColor};
  margin-left: 18px;
`

export const BasicInfoColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-row: 3;
  /* overflow: auto; */
  overflow-y: auto;
  overflow-x: hidden;
`

export const RightColumn = styled(BasicInfoColumn)`
  max-width: 100%;
  gap: 24px;
`

export const RightColRow = styled.div<{ $gap?: number; $type?: 'provider' }>`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 40px);

  ${({ $gap }) => css`
    gap: ${$gap}px;
  `}
  align-items: center;

  ${({ $type }) =>
    $type === 'provider' &&
    css`
      color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.iconColor};
    `}

  span {
    color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.propertyNameColor};
  }
`

export const BasicInfoSeparator = styled.div`
  grid-row: 3;
  grid-column: 2;
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.separatorLineColor};
`

export const BasicInfoTreeViewContainer = styled.div`
  grid-column: 2/ 4;
  grid-row: 3;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const BasicInfoSubTree = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > ${StyledCollapseButton} {
    position: static;
    margin: 10px -14px -40px -28px;
    z-index: 1;
  }
`

export const PropertyName = styled.span`
  color: ${({ theme }) => theme.transactionDetailsPage.dataGridItem.propertyNameColor};
`

import { observer } from 'mobx-react'
import { FC, useContext, useState } from 'react'
import {
  ApiUrlContainer,
  CollapseButton,
  ResultFooter,
  ResultHeader,
  ResultItem,
  ResultList,
  ResultTimestamp,
  ResultValuesContainer,
} from '../CreateTransactionPage.styles'
import { StoreContext } from '@/components/App'
import { TransactionResponseParsed } from '../types'
import { ParserPill } from './ParserPill'

const Result: FC<TransactionResponseParsed & { isCollapsedInitially?: boolean }> = ({
  transactionId,
  proceedToken,
  proceedUrl,
  status,
  date,
  apiUrl,
  isCollapsedInitially,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedInitially)

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify({ transactionId, proceedToken, proceedUrl }))
  }

  const handleOpenInNewTab = () => {
    window.open(proceedUrl, '_blank')
  }

  return (
    <ResultItem>
      <ResultHeader>
        <ResultTimestamp>
          {date}
          <ParserPill status={status} />
        </ResultTimestamp>
        <CollapseButton $isCollapsed={isCollapsed} onClick={() => setIsCollapsed(!isCollapsed)}>
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M17.96 9.03999C18.2139 9.29383 18.2139 9.70539 17.96 9.95923L12.96 14.9592C12.7062 15.2131 12.2946 15.2131 12.0408 14.9592L7.04077 9.95923C6.78693 9.70539 6.78693 9.29383 7.04077 9.03999C7.29461 8.78615 7.70617 8.78615 7.96001 9.03999L12.5004 13.5804L17.0408 9.03999C17.2946 8.78615 17.7062 8.78615 17.96 9.03999Z"
              fill="currentColor"
            />
          </svg>
        </CollapseButton>
      </ResultHeader>

      <ApiUrlContainer>{apiUrl}</ApiUrlContainer>

      <ResultValuesContainer $isCollapsed={isCollapsed}>
        <label>Transaction ID</label>
        <div>
          <div>{transactionId}</div>
        </div>
        <label>Proceed Token</label>
        <div>
          <div>{proceedToken}</div>
        </div>
        <label>Proceed URL</label>
        <div>
          <div>{proceedUrl}</div>
        </div>
      </ResultValuesContainer>

      <ResultFooter>
        <button onClick={handleCopyToClipboard}>
          <svg width="21" height="20" viewBox="0 0 21 20">
            <path
              d="M10.7062 4.31367C12.2195 2.80039 14.673 2.80039 16.1863 4.31367C17.6996 5.82695 17.6996 8.28047 16.1863 9.79375L14.7132 11.2669C14.5016 11.4784 14.1587 11.4784 13.9471 11.2669C13.7356 11.0554 13.7356 10.7124 13.9471 10.5009L15.4203 9.02772C16.5105 7.9375 16.5105 6.16992 15.4203 5.0797C14.3301 3.98949 12.5625 3.98949 11.4723 5.0797L9.99912 6.55284C9.78759 6.76438 9.44462 6.76438 9.23309 6.55284C9.02156 6.34131 9.02156 5.99835 9.23309 5.78681L10.7062 4.31367ZM12.9454 7.55458C13.1569 7.76611 13.1569 8.10908 12.9454 8.32061L8.82061 12.4454C8.60908 12.6569 8.26611 12.6569 8.05458 12.4454C7.84304 12.2339 7.84304 11.8909 8.05458 11.6794L12.1794 7.55458C12.3909 7.34304 12.7339 7.34304 12.9454 7.55458ZM7.05284 8.73309C7.26438 8.94462 7.26438 9.28759 7.05284 9.49912L5.5797 10.9723C4.48949 12.0625 4.48949 13.8301 5.5797 14.9203C6.66992 16.0105 8.4375 16.0105 9.52772 14.9203L11.0009 13.4471C11.2124 13.2356 11.5554 13.2356 11.7669 13.4471C11.9784 13.6587 11.9784 14.0016 11.7669 14.2132L10.2937 15.6863C8.78047 17.1996 6.32695 17.1996 4.81367 15.6863C3.30039 14.173 3.30039 11.7195 4.81367 10.2062L6.28681 8.73309C6.49834 8.52156 6.84131 8.52156 7.05284 8.73309Z"
              fill="currentColor"
            />
          </svg>
          <span>Copy result to clipboard</span>
        </button>
        <button onClick={handleOpenInNewTab}>
          <svg width="21" height="20" viewBox="0 0 21 20">
            <path
              d="M12.8018 3.74967C12.8018 3.45052 13.0443 3.20801 13.3435 3.20801H16.5418C16.956 3.20801 17.2918 3.54379 17.2918 3.95801L17.2918 7.20051C17.2918 7.49966 17.0493 7.74217 16.7502 7.74217C16.451 7.74218 16.2085 7.49966 16.2085 7.20051L16.2085 5.05737L11.2998 9.96602C11.0883 10.1776 10.7453 10.1776 10.5338 9.96602C10.3223 9.75449 10.3223 9.41153 10.5338 9.19999L15.4425 4.29134H13.3435C13.0443 4.29134 12.8018 4.04883 12.8018 3.74967ZM3.7085 7.91634C3.7085 5.77624 5.44339 4.04134 7.5835 4.04134H10.0835C10.3827 4.04134 10.6252 4.28385 10.6252 4.58301C10.6252 4.88216 10.3827 5.12467 10.0835 5.12467H7.5835C6.0417 5.12467 4.79183 6.37455 4.79183 7.91634V12.9163C4.79183 14.4581 6.0417 15.708 7.5835 15.708H12.5835C14.1253 15.708 15.3752 14.4581 15.3752 12.9163V10.4163C15.3752 10.1172 15.6177 9.87467 15.9168 9.87467C16.216 9.87467 16.4585 10.1172 16.4585 10.4163V12.9163C16.4585 15.0564 14.7236 16.7913 12.5835 16.7913H7.5835C5.44339 16.7913 3.7085 15.0564 3.7085 12.9163V7.91634Z"
              fill="currentColor"
            />
          </svg>
          <span>Open proceed URL</span>
        </button>
      </ResultFooter>
    </ResultItem>
  )
}

export const Results: FC = observer(() => {
  const store = useContext(StoreContext)
  const { results } = store.CreateTransactionState

  return (
    <ResultList>
      {results.map((result, index) => (
        <Result {...result} key={index} isCollapsedInitially={index !== 0} />
      ))}
    </ResultList>
  )
})

import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import {
  ActionButtonContainer,
  ClearButton,
  Content,
  ContentDesktopColumn,
  CreateButton,
  Page,
  RequestBodyContainer,
  ResultList,
  SectionTitle,
  Title,
} from './CreateTransactionPage.styles'
import { StoreContext } from '@/components/App'

import { TransactionJSONBodyEditor } from './components/TransactionJSONBodyEditor'
import { TabbedPdfSelector } from './components/TabbedPdfSelector'
import { Results } from './components/Results'
import { RequestBodyForm } from './components/RequestBodyForm'
import { LoadingSpinner } from '@/components/Generic'
import { useTheme } from 'styled-components'

export const CreateTransactionPage: FC = observer(() => {
  const theme = useTheme()
  const store = useContext(StoreContext)
  const { createTransactionPage: trans } = store.TranslationsState.translations
  const { isLoading } = store.CreateTransactionState

  return (
    <Page>
      <Title>
        <span>
          <svg width="40" height="40" viewBox="0 0 40 40">
            <path
              d="M16.732 7.5C17.3186 7.5 17.7941 7.97551 17.7941 8.56209V14.0359L23.268 14.0359C23.8546 14.0359 24.3301 14.5115 24.3301 15.098C24.3301 15.6846 23.8546 16.1601 23.268 16.1601H17.7941V21.634C17.7941 22.2206 17.3186 22.6961 16.732 22.6961C16.1455 22.6961 15.6699 22.2206 15.6699 21.634V16.1601H10.1961C9.6095 16.1601 9.13399 15.6846 9.13399 15.098C9.13399 14.5115 9.6095 14.0359 10.1961 14.0359H15.6699V8.56209C15.6699 7.97551 16.1455 7.5 16.732 7.5ZM23.7582 8.56209C23.7582 7.97551 24.2337 7.5 24.8203 7.5H24.902C29.0982 7.5 32.5 10.9018 32.5 15.098V24.902C32.5 29.0982 29.0982 32.5 24.902 32.5H15.098C10.9018 32.5 7.5 29.0982 7.5 24.902V21.9063C7.5 21.3197 7.97551 20.8442 8.56209 20.8442C9.14867 20.8442 9.62418 21.3197 9.62418 21.9063V24.902C9.62418 27.9251 12.0749 30.3758 15.098 30.3758H24.902C27.9251 30.3758 30.3758 27.9251 30.3758 24.902V15.098C30.3758 12.0749 27.9251 9.62418 24.902 9.62418H24.8203C24.2337 9.62418 23.7582 9.14867 23.7582 8.56209Z"
              fill="currentColor"
            />
          </svg>
        </span>
        {trans.pageTitle}
      </Title>

      <Content>
        <ContentDesktopColumn>
          <RequestBodyForm />

          <TransactionJSONBodyEditor />

          <TabbedPdfSelector />

          <ActionButtonContainer>
            <ClearButton>
              <svg width="25" height="24" viewBox="0 0 25 24">
                <path
                  d="M12.15 3.3C7.53842 3.3 3.8 7.03842 3.8 11.65C3.8 16.2616 7.53842 20 12.15 20C16.7616 20 20.5 16.2616 20.5 11.65C20.5 7.03842 16.7616 3.3 12.15 3.3ZM2.5 11.65C2.5 6.32045 6.82045 2 12.15 2C17.4795 2 21.8 6.32045 21.8 11.65C21.8 16.9795 17.4795 21.3 12.15 21.3C6.82045 21.3 2.5 16.9795 2.5 11.65ZM16.1451 7.6548C16.399 7.90864 16.399 8.3202 16.1451 8.57404L13.0692 11.65L16.1451 14.7259C16.3989 14.9797 16.3989 15.3913 16.1451 15.6451C15.8913 15.8989 15.4797 15.8989 15.2259 15.6451L12.15 12.5692L9.07407 15.6451C8.82023 15.8989 8.40868 15.8989 8.15484 15.6451C7.90099 15.3913 7.90099 14.9797 8.15484 14.7259L11.2307 11.65L8.1548 8.57404C7.90096 8.3202 7.90096 7.90864 8.1548 7.6548C8.40864 7.40096 8.8202 7.40096 9.07404 7.6548L12.15 10.7307L15.2259 7.6548C15.4797 7.40096 15.8913 7.40096 16.1451 7.6548Z"
                  fill="currentColor"
                />
              </svg>
              {trans.clear}
            </ClearButton>
            <CreateButton
              onClick={() => {
                store.CreateTransactionState.postCreateTransaction()
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <LoadingSpinner
                  centered
                  width="24px"
                  centerColor={theme.createTransactionPage.primaryButton.bgColor}
                />
              ) : (
                <>
                  {' '}
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path
                      d="M10.0392 4.5C10.3912 4.5 10.6765 4.78531 10.6765 5.13725V8.42157L13.9608 8.42157C14.3127 8.42157 14.598 8.70688 14.598 9.05882C14.598 9.41077 14.3127 9.69608 13.9608 9.69608H10.6765V12.9804C10.6765 13.3323 10.3912 13.6176 10.0392 13.6176C9.68727 13.6176 9.40196 13.3323 9.40196 12.9804V9.69608H6.11765C5.7657 9.69608 5.48039 9.41077 5.48039 9.05882C5.48039 8.70688 5.7657 8.42157 6.11765 8.42157H9.40196V5.13725C9.40196 4.78531 9.68727 4.5 10.0392 4.5ZM14.2549 5.13725C14.2549 4.78531 14.5402 4.5 14.8922 4.5H14.9412C17.4589 4.5 19.5 6.54106 19.5 9.05882V14.9412C19.5 17.4589 17.4589 19.5 14.9412 19.5H9.05882C6.54105 19.5 4.5 17.4589 4.5 14.9412V13.1438C4.5 12.7918 4.78531 12.5065 5.13725 12.5065C5.4892 12.5065 5.77451 12.7918 5.77451 13.1438V14.9412C5.77451 16.7551 7.24495 18.2255 9.05882 18.2255H14.9412C16.7551 18.2255 18.2255 16.7551 18.2255 14.9412V9.05882C18.2255 7.24495 16.7551 5.77451 14.9412 5.77451H14.8922C14.5402 5.77451 14.2549 5.4892 14.2549 5.13725Z"
                      fill="currentColor"
                    />
                  </svg>
                  {trans.create}
                </>
              )}
            </CreateButton>
          </ActionButtonContainer>
        </ContentDesktopColumn>
        <ContentDesktopColumn>
          <SectionTitle>{trans.result}</SectionTitle>
          <Results />
        </ContentDesktopColumn>
      </Content>
    </Page>
  )
})

import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import {
  CheckboxWrapper,
  RequestBodyContainer,
  SectionTitle,
} from '../CreateTransactionPage.styles'
import { StoreContext } from '@/components/App'
import { Checkbox, SelectInput, TextInput } from '@/components/Generic'
import { toSelect } from '../methods'
import { StyledLabel } from '@/components/Generic/TextInput/TextInput.styles'
import { LANGUAGES_LIST } from '../constants'

export const RequestBodyForm: FC = observer(() => {
  const store = useContext(StoreContext)
  const { createTransactionPage: trans } = store.TranslationsState.translations
  const {
    isLoading,
    configIds,
    themeIds,
    configuration,
    language,
    themeId,
    redirectUrl,
    phoneNumber,
    email,
    isV3Mode,
    configurationOption,
  } = store.CreateTransactionState

  return (
    <RequestBodyContainer>
      <SectionTitle>{trans.newTransaction}</SectionTitle>

      <StyledLabel>{trans.editConfiguration + ' (configuration ID)'}</StyledLabel>
      <SelectInput
        value={configurationOption}
        options={configIds}
        disabled={isLoading}
        onChange={(e) => store.CreateTransactionState.setConfiguration(e.value)}
      />

      <StyledLabel>{trans.preferredLanguage}</StyledLabel>
      <SelectInput
        value={{ value: language, label: language }}
        options={LANGUAGES_LIST}
        disabled={isLoading}
        onChange={(e) => store.CreateTransactionState.setLanguage(e.value)}
      />

      <StyledLabel>{trans.themeId}</StyledLabel>
      <SelectInput
        value={{ value: themeId, label: themeId }}
        options={themeIds}
        disabled={isLoading}
        onChange={(e) => store.CreateTransactionState.setThemeId(e.value)}
      />

      <TextInput
        value={redirectUrl}
        name="redirectUrl"
        disabled={isLoading}
        required
        onChange={(val) => store.CreateTransactionState.setRedirectUrl(val)}
        label={trans.redirectURL}
      />

      <TextInput
        value={phoneNumber}
        name="phoneNumber"
        disabled={isLoading}
        required
        onChange={(val) => store.CreateTransactionState.setPhoneNumber(val)}
        label={trans.phoneNumber}
      />

      <TextInput
        value={email}
        name="email"
        disabled={isLoading}
        required
        onChange={(val) => store.CreateTransactionState.setEmail(val)}
        label={trans.email}
      />

      <CheckboxWrapper>
        <Checkbox
          value={isV3Mode}
          label={trans.modularization}
          onChange={() => store.CreateTransactionState.setV3Mode(!isV3Mode)}
        />
      </CheckboxWrapper>
    </RequestBodyContainer>
  )
})

import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import {
  BasicInfoColumn,
  BasicInfoContainer,
  BasicInfoLine,
  BasicInfoBottomContent,
  BasicInfoSeparator,
  BasicInfoSubTree,
  BasicInfoTreeViewContainer,
  PropertyName,
  RightColRow,
  RightColumn,
} from './BasicInfo.styles'
import {
  ContainerTitle,
  RootLine,
  RootLineContainer,
  TopContent,
  TreeRow,
} from '../Components.styles'
import { Icon } from '../../Icons/IconMatrix'
import { formatUnixDate } from '../../methods'
import { Pill } from '../Pill'
import { Tag } from '../Tag'
import { ProviderSummary } from './ProviderSummary'
import { Provider } from '../../types'
import { ValueButton } from '../ValueButton'
import { RecursiveObjectRenderer } from '../RecursiveObjectRenderer/RecursiveObjectRenderer'
import { StoreContext } from '@/components/App'

interface BasicInfoProps {
  transaction: any
  providers?: Array<Provider>
}

export const BasicInfo: FC<BasicInfoProps> = observer(({ transaction, providers }) => {
  const store = useContext(StoreContext)
  const { transactionListHeaders: transHeaders, singleTransactionPage: trans } =
    store.TranslationsState.translations

  const staticPropertyKeys = [
    'transactionId',
    'configurationId',
    'creationUnixTimeSeconds',
    'completionUnixTimeSeconds',
    'redirectUrl',
    'providers',
    'tags',
    'status',
  ]

  const dynamicPropertyKeys = Object.keys(transaction).filter(
    (key) => !staticPropertyKeys.includes(key)
  )

  return (
    <BasicInfoContainer>
      <TopContent>
        <Icon icon="basicInfo" />
        <ContainerTitle>
          {trans.basicInformation}
          {transaction?.status && <Pill status={transaction.status} />}
          {transaction?.result && <Pill status={transaction.result} />}
        </ContainerTitle>
      </TopContent>

      {/* <BasicInfoLine /> */}

      <BasicInfoColumn>
        <BasicInfoBottomContent>
          <RootLineContainer $hide>
            <RootLine />
          </RootLineContainer>

          <BasicInfoTreeViewContainer>
            <TreeRow>
              <PropertyName>{transHeaders.transactionId}:</PropertyName>
              {transaction.transactionId}
            </TreeRow>
            <TreeRow>
              <PropertyName>{transHeaders.configurationId}:</PropertyName>
              {transaction.configurationId}
            </TreeRow>
            <TreeRow>
              <PropertyName>{transHeaders.creationUnixTimeSeconds}:</PropertyName>
              {formatUnixDate(transaction.creationUnixTimeSeconds)}
            </TreeRow>
            <TreeRow>
              <PropertyName>{transHeaders.completionUnixTimeSeconds}:</PropertyName>
              {formatUnixDate(transaction.completionUnixTimeSeconds)}
            </TreeRow>
            <TreeRow>
              <PropertyName>{transHeaders.redirectUrl}:</PropertyName>
              <ValueButton action="open" actionData={transaction.redirectUrl} />
              <ValueButton action="copy" actionData={transaction.redirectUrl} />
            </TreeRow>

            <BasicInfoSubTree>
              {!!dynamicPropertyKeys?.length &&
                dynamicPropertyKeys.map((key, v) => (
                  <RecursiveObjectRenderer
                    key={v}
                    data={transaction[key]}
                    propertyKey={key}
                    isFirstLoopBasicInfo
                  />
                ))}
            </BasicInfoSubTree>
          </BasicInfoTreeViewContainer>
        </BasicInfoBottomContent>
      </BasicInfoColumn>

      <BasicInfoSeparator />

      <RightColumn>
        <RightColRow $gap={16}>
          <span>{transHeaders.tags}:</span>
          {!!transaction?.tags?.length &&
            transaction.tags.map((tag: string, v) => <Tag key={v} tag={tag} />)}
        </RightColRow>

        {!!providers?.length &&
          providers.map((provider: Provider, v: number) => (
            <ProviderSummary key={v} provider={provider} />
          ))}
      </RightColumn>
    </BasicInfoContainer>
  )
})

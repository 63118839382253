import { format } from 'date-fns'

export const camelToTitle = (camel?: string) => {
  if (!camel) return ''

  return camel.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())
}

export const formatUnixDate = (unixTimeSeconds: any): string => {
  if (typeof unixTimeSeconds !== 'number') return ''
  const date = new Date(unixTimeSeconds * 1000)

  return format(date, 'yyyy-MM-dd HH:mm:ss') + ' UTC'
}

export const stringifyArraysInObject = (obj: any): any => {
  if (Array.isArray(obj)) {
    // Process each element in the array recursively
    const processedArray = obj.map((element) => stringifyArraysInObject(element))
    // Return the stringified version of the processed array
    return JSON.stringify(processedArray)
  } else if (obj !== null && typeof obj === 'object') {
    const newObj: any = {}
    // Iterate over each key in the object
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Recursively process each property
        newObj[key] = stringifyArraysInObject(obj[key])
      }
    }
    return newObj
  } else {
    // Return other data types as is
    return obj
  }
}

export const downloadJsonData = (data: any, filename?: string) => {
  const blob = new Blob([JSON.stringify(data, null, 2)], {
    type: 'application/json',
  })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'data.json'
  a.click()
  URL.revokeObjectURL(url)
}

import { TransactionPostRequest } from './types'

export const DEFAULT_REDIRECT_URL = 'https://signicat.com'

export const LANGUAGES_LIST = [
  { label: 'en', value: 'en' },
  { label: 'de', value: 'de' },
  { label: 'fr', value: 'fr' },
  { label: 'it', value: 'it' },
  { label: 'pl', value: 'pl' },
]

export const THEME_LIST = [{ label: 'signicatTheme', value: 'signicatTheme' }]

export const initObjV2: TransactionPostRequest = {
  configurationId: 'signicat.with.micropayment',
  preferredLanguage: 'en',
  redirectUrl: DEFAULT_REDIRECT_URL,
  themeId: 'signicatTheme',
  user: {
    email: 'someone@signicat.com',
    phoneNumber: '',
  },
}

export const CONFIGURATION_LIST_V2 = [
  { label: 'Micropayment + Signing + VideoId', value: 'signicat.with.micropayment' },
  { label: 'Signing + VideoId', value: 'signicat.without.micropayment' },
  { label: 'Idin + VideoId', value: 'signicat.eid.idin.videoId' },
  // { label: 'Signing + VideoId + Tink + Address Verification (Ikano)', value: 'ikano.de' },
  // { label: 'Signing + VideoId + Klarna (Jitpay)', value: 'jitpay.eu' },
  // { label: 'Klarna', value: 'jitpay.eu.micropayment' },
  // { label: 'Micropayment + Signing + VideoID (BMW)', value: 'bmw.de' },
]

export const CONFIGURATION_LIST_V3 = [
  { label: 'Address Verification', value: 'demo.addressVerification' },
  { label: 'Signing + VideoId + Address Verification', value: 'demo.addressVerification.2' },
]

export const initObjV3 = (configurationId: string): TransactionPostRequest => {
  if (configurationId === 'demo.addressVerification') {
    return {
      configurationId: 'demo.addressVerification',
      preferredLanguage: 'en',
      redirectUrl: DEFAULT_REDIRECT_URL,
      user: {
        email: 'someone@signicat.com',
        firstName: 'Erika',
        lastName: 'Mustermann',
        address: {
          city: 'Köln',
          postalCode: '51147',
          street: 'Heidestrasse 17',
        },
      },
    }
  } else {
    return {
      configurationId: configurationId,
      preferredLanguage: 'en',
      redirectUrl: DEFAULT_REDIRECT_URL,
      user: {
        email: 'someone@signicat.com',
        firstName: 'Erika',
        lastName: 'Mustermann',
        address: {
          city: 'Köln',
          postalCode: '51147',
          street: 'Heidestrasse 17',
        },
        dateOfBirth: '1980-01-01',
        nationalities: ['POL', 'DEU'],
        bankInformation: {
          accountHolder: 'Erika Mustermann',
          iban: 'DE62888888880012345678',
        },
      },
    }
  }
}

export const IS_V3_MODE_ON_OPTIONS = [
  { value: false, label: 'off' },
  { value: true, label: 'on' },
]

export const PDF_NAME_LIST = [{ label: 'pdfExample', value: 'pdfExample' }]

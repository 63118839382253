import { forwardRef } from 'react'
import styled, { useTheme } from 'styled-components'

export const StyledCollapseButton = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  /* margin: 10px -14px -40px -28px; */
  width: 14px;
  height: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 5; */

  position: absolute;
  top: 10px;
  left: -20px;
`

interface CollapseButtonProps {
  onClick: () => void
  isCollapsed: boolean
}

export const CollapseButton = forwardRef<HTMLButtonElement, CollapseButtonProps>(
  ({ onClick, isCollapsed }, ref) => {
    const theme = useTheme()

    return (
      <StyledCollapseButton onClick={onClick} ref={ref}>
        {isCollapsed ? (
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Chevron_Down">
              <rect x="0.5" y="0.5" width="13" height="13" rx="6.5" fill="white" />
              <rect
                x="0.5"
                y="0.5"
                width="13"
                height="13"
                rx="6.5"
                stroke={theme.transactionDetailsPage.dataGridItem.iconColor}
              />
              <path
                id="Vector 967"
                d="M4 7H8.2H10"
                stroke={theme.transactionDetailsPage.dataGridItem.iconColor}
                strokeLinecap="round"
              />
              <path
                id="Vector 968"
                d="M7 4L7 8.2L7 10"
                stroke={theme.transactionDetailsPage.dataGridItem.iconColor}
                strokeLinecap="round"
              />
            </g>
          </svg>
        ) : (
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Chevron_Down">
              <rect x="0.5" y="0.5" width="13" height="13" rx="6.5" fill="white" />
              <rect
                x="0.5"
                y="0.5"
                width="13"
                height="13"
                rx="6.5"
                stroke={theme.transactionDetailsPage.dataGridItem.treeLineColor}
              />
              <path
                id="Vector 967"
                d="M4 7H8.2H10"
                stroke={theme.transactionDetailsPage.dataGridItem.treeLineColor}
                strokeLinecap="round"
              />
            </g>
          </svg>
        )}
      </StyledCollapseButton>
    )
  }
)

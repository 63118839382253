import { axiosInstance } from '@/axios/AxiosInstance'
import { StoreContext } from '@/components/App'
import { Button, Popup } from '@/components/Generic'
import { observer } from 'mobx-react'
import { FC, useCallback, useContext } from 'react'
import { toast } from 'react-toastify'
import { useTheme } from 'styled-components'

interface DeletePiiDataPopupProps {}

export const DeletePiiDataPopup: FC<DeletePiiDataPopupProps> = observer(() => {
  const theme = useTheme()

  const store = useContext(StoreContext)
  const { singleTransactionPage: trans } = store.TranslationsState.translations
  const { selectedTenant, isV3, transactionId } = store.TransactionDetailsState
  const { isDeleting, isDeletePopupVisible } = store.TransactionDetailsState

  const deletePiiData = useCallback(async () => {
    await axiosInstance.delete(`/transactions/${transactionId}/pii-data`, {
      withCredentials: true,
      params: {
        tenantId: selectedTenant,
        isV3,
      },
    })
  }, [selectedTenant])

  const setVisibility = (boolean: boolean) => {
    store.TransactionDetailsState.setDeletePopupVisibility(boolean)
  }

  return (
    <Popup
      visibility={isDeletePopupVisible}
      handleVisibility={(boolean: boolean) => {
        setVisibility(boolean)
      }}
      {...theme.popup}
      title={trans.deleteModalTitle}
      footer={
        <>
          <Button
            styleName="primary"
            disabled={isDeleting}
            onClick={() => {
              store.TransactionDetailsState.setDeleting(true)

              deletePiiData()
                .finally(() => {
                  store.TransactionDetailsState.setDeleting(false)
                  // setTransactionData({})
                  store.TransactionDetailsState.fetchTransaction()
                })
                .catch(() => toast(String(trans.unableToDeletePiiData)))

              setVisibility(false)
            }}
          >
            {trans.confirm}
          </Button>
          <Button styleName="secondary" disabled={isDeleting} onClick={() => setVisibility(false)}>
            {trans.decline}
          </Button>
        </>
      }
    />
  )
})

import { observer } from 'mobx-react'
import { FC, useContext, useEffect } from 'react'
import {
  BottomContent,
  CollapsibleContainer,
  ContainerTitle,
  RootLine,
  RootLineContainer,
  TopContent,
  TreeRow,
  TreeViewContainer,
} from './Components.styles'
import { Icon } from '../Icons/IconMatrix'
import { camelToTitle } from '../methods'
import { Caret } from '../Icons/Caret'
import { Pill } from './Pill'
import { Provider } from '../types'
import { StoreContext } from '@/components/App'
import { RecursiveObjectRenderer } from './RecursiveObjectRenderer/RecursiveObjectRenderer'

interface ProviderDataProps {
  provider: Provider
}

export const ProviderData: FC<ProviderDataProps> = observer(({ provider }) => {
  const store = useContext(StoreContext)
  const { containerStates } = store.TransactionDetailsState

  const isCollapsed = containerStates[provider.providerName]?.isCollapsed
  const isHighlighted = containerStates[provider.providerName]?.isHighlighted

  const setIsCollapsed = (isCollapsed: boolean) => {
    store.TransactionDetailsState.setContainerCollapsedState(provider.providerName, isCollapsed)
  }

  useEffect(() => {
    if (!containerStates[provider.providerName]) {
      store.TransactionDetailsState.setContainerCollapsedState(provider.providerName, true)
    }
  }, [])

  const providerProperties = Object.keys(provider).filter((key) => key !== 'providerName')

  return (
    <CollapsibleContainer
      $isCollapsed={isCollapsed}
      id={provider.providerName}
      $isHighlighted={isHighlighted}
    >
      <TopContent>
        <Icon icon={provider.name} />
        <ContainerTitle onClick={() => setIsCollapsed(!isCollapsed)}>
          {camelToTitle(provider.providerName)}
          {provider?.status && <Pill status={provider.status} />}
          {provider?.result && <Pill status={provider.result} />}
        </ContainerTitle>
        <Caret isCollapsed={isCollapsed} set={setIsCollapsed} />
      </TopContent>

      <BottomContent>
        <RootLineContainer $isCollapsed={isCollapsed}>
          <RootLine />
        </RootLineContainer>

        <TreeViewContainer>
          {!!providerProperties?.length &&
            providerProperties.map((key, v) => (
              <RecursiveObjectRenderer key={v} data={provider[key]} propertyKey={key} isFirstLoop />
            ))}
        </TreeViewContainer>
      </BottomContent>
    </CollapsibleContainer>
  )
})

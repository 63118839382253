import { FC, ReactNode } from 'react'
import { Route, RouteProps, Switch } from 'wouter'

//  Pages
import { NotFoundPage } from '@/pages/NotFoundPage'
import { LoginPage } from '@/pages/LoginPage/LoginPage'
import { TransactionDetailsPage } from '@/pages/TransactionDetailsPage/TransactionDetailsPage'
// Layouts
import { UnauthorizedLayout } from '@/layouts/UnauthorizedLayout'
import { DefaultLayout } from '@/layouts/DefaultLayout'
import { TransactionListPage } from '@/pages/TransactionListPage/TransactionListPage'
import { DevPage } from '@/pages/Dev'
import { CreateTransactionPage } from '@/pages/CreateTransactionPage/CreateTransactionPage'
import { ErrorPage } from '@/pages/ErrorPage'
import { UnauthorizedPage } from '@/pages/UnathorizedPage'

interface LayoutRouteProps {
  path?: RouteProps['path']
  page: ReactNode
  layout?: FC<{ children: ReactNode }>
}

const LayoutRoute: FC<LayoutRouteProps> = ({ path, layout: LayoutComponent, page }) => {
  return <Route path={path}>{LayoutComponent ? <LayoutComponent children={page} /> : page}</Route>
}

export const ContentRouter: FC = () => (
  <Switch>
    <LayoutRoute path="/transactions" layout={DefaultLayout} page={<TransactionListPage />} />

    <LayoutRoute
      path="/transactions/:transactionId"
      page={<TransactionDetailsPage />}
      layout={DefaultLayout}
    />

    <LayoutRoute
      path="/create-transaction"
      page={<CreateTransactionPage />}
      layout={DefaultLayout}
    />

    <LayoutRoute path="/" page={<LoginPage />} layout={UnauthorizedLayout} />
    <LayoutRoute path="/login" page={<LoginPage />} layout={UnauthorizedLayout} />

    {/* Development route */}
    {/* {DEV_MODE && ( */}
    <LayoutRoute path="/dev" page={<DevPage />} layout={DefaultLayout} />
    {/* )} */}

    <LayoutRoute path="/error" page={<ErrorPage />} layout={UnauthorizedLayout} />
    <LayoutRoute path="/unauthorized" page={<UnauthorizedPage />} layout={UnauthorizedLayout} />
    {/* Default route in a switch */}
    <LayoutRoute page={<NotFoundPage />} layout={UnauthorizedLayout} />
  </Switch>
)

import { FC } from 'react'
import { Number } from '../Components.styles'
import { StringValue } from './ValueTypes/StringValue'

export const ResolveValueType: FC<{ value: any }> = ({ value }) => {
  const type = typeof value

  switch (type) {
    case 'string':
      return <StringValue value={value.toString()} />
    case 'number':
      return <Number>{value.toString()}</Number>
    case 'boolean':
      return <Boolean>{value.toString()}</Boolean>

    default:
      return <></>
  }
}
